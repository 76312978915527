<template>
  <div id="dialogPopupModal">
    <div class='dialog-wrap'>
        <div id="titleDialogPopUp" ><h5>{{title}}</h5></div>
        <div v-html="body"></div>
        <div class="dialog-buttons-wrap">
          <button type="button" @click="answerYes" class="btn btn-primary">Ja</button>
          <button type="button" @click="answerNo" class="btn btn-primary">Nein</button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'popup-dialog-modal',
  props: ['title', 'body'],
  methods: {
    answerYes() {
      this.$emit('answerYes');
    },
    answerNo() {
      this.$emit('answerNo');
    },
  },
};
</script>

<style scoped>

.dialog-buttons-wrap {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 40px;
}

#dialogPopupModal {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 99999;
  font-family: 'Source Sans Pro', Verdana, sans-serif;
  line-height: 23px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: ease 0.25s all;
  font-size: 20px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.dialog-wrap {
  padding: 20px;
  max-width: 1024px;
  width: 80%;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px rgba(0,0,0,1);
  border-radius: 3px;
  background: rgb(255, 255, 255);
  overflow-y: auto;
}

#dialogPopupModal #titleDialogPopUp label, #dialogPopupModal #titleDialogPopUp li{
  color: #000;
  font-size: 1rem;
  font-weight: normal;
}
#dialogPopupModal {

}
#dialogPopupModal #titleDialogPopUp h5.title{
  color: #ffa500;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold
}
</style>
