





































































































































































































































































































































































































































































































































import Vue from 'vue';
import popupModalWithSlot from '@/components/form/popup-modal-with-slot.vue';
import { LOGO_JPG } from '@/config/images';

export default Vue.extend({
  name: 'calculated-risks-tooltip',
  components: {
    popupModalWithSlot,
  },
  data() {
    return {
      LOGO_JPG,
      modalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalOpen = false;
    },
  },
});
