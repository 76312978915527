<template>
  <div class="popup-main">
    <div class="popup-wrap" :style="{width, height}">
        <div class="popup-content">
          <h5>{{title}}</h5>
          <div>
            <slot />
          </div>
        </div>
      <span class="popup-close" @click="closeModal">x</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '80%',
    },
    height: {
      type: String,
      default: '90%',
    },
  },
  methods: {
    closeModal() {
      this.$emit('modal_close');
    },
  },
};
</script>

<style scoped>
.popup-main{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 99999;
  font-family: 'Source Sans Pro', Verdana, sans-serif;
  line-height: 23px;
}
.popup-content {
  overflow-y: scroll;
  white-space: normal;
}
.popup-main .popup-content{
  display: block;
  height: 100%;
  overflow-y: scroll;
}
.popup-wrap{
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px rgba(0,0,0,1);
  border-radius: 3px;
  background: rgb(255, 255, 255);
}
.popup-main .popup-content, .popup-main .popup-content li{
  color: #000;
  font-size: 1rem;
  font-weight: normal;
}
.popup-main .popup-close{
  width: 25px;
  height: 25px;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  transition: ease 0.25s all;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  border-radius: 1000px;
  background: rgba(0,0,0,0.7);
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  line-height: 100%;
  color: #fff;
  cursor: pointer;
}
.popup-main .popup-content h5.title{
  color: #ffa500;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold
}
</style>
